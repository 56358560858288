import {createReducer, on} from '@ngrx/store';
import * as ContractsActions from '../actions/contract.actions';
import {ContractsDto} from "../models/contract.model";

export interface ContractState {
  contracts: {
    data: ContractsDto[],
    totalRecords: number
  }
  error: any;
}

export const initialState: ContractState = {
  contracts: {
    data: [],
    totalRecords: 0
  },
  error: null,
};

export const contractReducer = createReducer(
  initialState,
  on(ContractsActions.loadContractsSuccess, (state, {data, totalRecords}) => ({
    ...state,
    contracts: {data: [...data], totalRecords}
  })),
  on(ContractsActions.loadContractsFailure, (state, {error}) => ({...state, error})),
  on(ContractsActions.addContractSuccess, (state, {contract}) => ({
    ...state,
    contracts: {data: [...state.contracts.data, contract], totalRecords: state.contracts.totalRecords + 1},
  })),
  on(ContractsActions.addContractFailure, (state, {error}) => ({...state, error})),
  on(ContractsActions.updateContractSuccess, (state, {contract}) => ({
    ...state,
    contracts: {
      data: state.contracts.data.map(u => (u.id === contract.id ? contract : u)),
      totalRecords: state.contracts.totalRecords
    },
  })),
  on(ContractsActions.updateContractFailure, (state, {error}) => ({...state, error})),
  on(ContractsActions.deleteContractSuccess, (state, {id}) => ({
    ...state,
    contracts: {
      data: state.contracts.data.filter(user => user.id !== id),
      totalRecords: state.contracts.totalRecords - 1
    }
  })),
  on(ContractsActions.deleteContractFailure, (state, {error}) => ({...state, error}))
);
