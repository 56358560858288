import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {ContractService} from '../services/contract.service';
import * as ContractActions from '../actions/contract.actions';
import {catchError, map, mergeMap} from 'rxjs/operators';
import {of} from 'rxjs';

@Injectable()
export class ContractEffects {
  constructor(private actions$: Actions, private contractService: ContractService) {
  }

  loadUsers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ContractActions.loadContracts),
      mergeMap(action =>
        this.contractService.getUser(action.filter).pipe(
          map(contracts => ContractActions.loadContractsSuccess({
            data: contracts.data,
            totalRecords: contracts.totalRecords
          })),
          catchError(error => of(ContractActions.loadContractsFailure({error})))
        )
      )
    )
  );

  addUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ContractActions.addContract),
      mergeMap(action =>
        this.contractService.addUser(action.contract).pipe(
          map(contract => ContractActions.addContractSuccess({contract})),
          catchError(error => of(ContractActions.addContractFailure({error})))
        )
      )
    )
  );

  updateUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ContractActions.updateContract),
      mergeMap(action =>
        this.contractService.updateUser(action.id, action.contract).pipe(
          map(contract => ContractActions.updateContractSuccess({contract})),
          catchError(error => of(ContractActions.updateContractFailure({error})))
        )
      )
    )
  );

  deleteUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ContractActions.deleteContract),
      mergeMap(action =>
        this.contractService.deleteUser(action.id).pipe(
          map(() => ContractActions.deleteContractSuccess({id: action.id})),
          catchError(error => of(ContractActions.deleteContractFailure({error})))
        )
      )
    )
  );
}
