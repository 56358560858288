import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import * as SubContractActions from '../actions/subContract.actions';
import {catchError, map, mergeMap} from 'rxjs/operators';
import {of} from 'rxjs';
import {SubContractService} from "../services/subContract.service";

@Injectable()
export class SubContractEffects {
  constructor(private actions$: Actions, private subContractService: SubContractService) {}

  loadSubContract$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SubContractActions.loadSubContracts),
      mergeMap(action =>
        this.subContractService.getSubContract(action.filter).pipe(
          map(subContracts => SubContractActions.loadSubContractsSuccess({ subContracts })),
          catchError(error => of(SubContractActions.loadSubContractsFailure({ error })))
        )
      )
    )
  );

  addSubContract$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SubContractActions.addSubContract),
      mergeMap(action =>
        this.subContractService.addSubContract(action.newSubContract).pipe(
          map(newSubContract => SubContractActions.addSubContractSuccess({ newSubContract })),
          catchError(error => of(SubContractActions.addSubContractFailure({ error })))
        )
      )
    )
  );

  updateSubContract$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SubContractActions.updateSubContract),
      mergeMap(action =>
        this.subContractService.updateSubContract(action.id, action.updateContract).pipe(
          map(updateContract => SubContractActions.updateSubContractSuccess({ updateContract })),
          catchError(error => of(SubContractActions.updateSubContractFailure({ error })))
        )
      )
    )
  );

  deleteSubContract$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SubContractActions.deleteSubContract),
      mergeMap(action =>
        this.subContractService.deleteSubContract(action.id).pipe(
          map(() => SubContractActions.deleteSubContractSuccess({ id: action.id })),
          catchError(error => of(SubContractActions.deleteSubContractFailure({ error })))
        )
      )
    )
  );
}
